import React from "react";
// import ReactDOM from 'react-dom/client';
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import YearInReviewAnimation from "./YearInReviewAnimation.jsx";
import { ParallaxProvider } from "react-scroll-parallax";

var membershipTier = "GR";
var memberMiles = 0;

console.log('before');
console.log(membershipTier);
console.log(memberMiles);


const url = window.location.search;
console.log('url is....', url);
const params = new URLSearchParams(url);
console.log('params',params);

if(params){
  const membership = params.get('membership');
  const miles = params.get('miles');

  if(membership == "gr" || membership == "GR" || membership == "sl" || membership == "SL" || membership == "go" || membership == "GO" || membership == "dm" || membership == "DM") {
    membershipTier = membership.toUpperCase();
  }//SL, GO, DM

  if(!isNaN(miles)){
    console.log('is an integer');
    if(miles == 0 || miles > 0){
      memberMiles = miles;
    }
  }
 
  console.log('this is after ');
  console.log(membershipTier);
  console.log(memberMiles);
}

const data = {
  general: {
    isLoading: true,
    testing: true,
    year: 2024,
    cathayLogo:
      "https://cxyearinreview.rhmdev.info/public-assets/general/cathayLogo.svg",
    // membershipTier: "GR", //SL, GO, DM
    membershipTier: membershipTier,
    greenMemberImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/greenMember.svg",
    silverMemberImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/silverMember.svg",
    goldMemberImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/goldMember.svg",
    diamondMemberImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/diamondMember.svg",
    mainParallaxImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/main-parallax.jpg",
    cloudParallaxOneImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/Clouds_3.png",
    cloudParallaxTwoImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/Clouds_2.png",
    portraitLandscapeRotateIcon: "https://cxyearinreview.rhmdev.info/public-assets/general/rotateIcon.svg",
    languageList: [
      {
        languageCode: "en",
        languageLabel: "English",
        languageUrl: "url",
      },
      {
        languageCode: "ent",
        languageLabel: "English (Taiwan China)",
        languageUrl: "url",
      },
      {
        languageCode: "zh_cn",
        languageLabel: "繁體中文(香港特別行政區)",
        languageUrl: "url",
      },
      {
        languageCode: "zh",
        languageLabel: "繁體中文(中國台灣)",
        languageUrl: "url",
      },
      {
        languageCode: "zhh",
        languageLabel: "简体中文( 中国內地)",
        languageUrl: "url",
      },
    ],
    currentLanguage: "en",
    languageIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/general/languageIcon.svg",
    languageIconSelected:
      "https://cxyearinreview.rhmdev.info/public-assets/general/languageIconSelected.svg",
    shareIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/general/shareIcon.svg",
    shutterImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/general/shutter.svg",
    windowFrameGreenImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/general/windowFrameGreen.svg",
    windowFrameSilverImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/general/windowFrameSilver.svg",
    windowFrameGoldImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/general/windowFrameGold.svg",
    windowFrameDiamondImgUrl:
      "https://cxyearinreview.rhmdev.info/public-assets/general/windowFrameDiamond.svg",
    messageAfterLinkCopy: "link copied",
    onPageView: (pageId) => {
      console.log('page view called - pageID', pageId);
    },
  },

  slide0: {
    landingTop: "Cathay Membership Snapshots",
    landingTitle: "Unpack your year in the skies",
    landingScrollLabel: "Scroll to begin",
    arrowDownIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/slide-0/arrowDownIcon.svg",
    backgroundImage: "https://cxyearinreview.rhmdev.info/public-assets/exports/windowTop.png",
    disclaimer:
      "Data reflective from 01 January up to 31 October 2024 and inclusive of redemption nominee activity.",
  },

  slide1: {
    memberYearDescription: "Time flies! You’ve been a member for",
    memberYearCount: 6,
    memberLabel: "GREEN MEMBER",
    memberYearUnit: "year",
    backgroundImage: "https://cxyearinreview.rhmdev.info/public-assets/exports/BgOnly01.jpg",
  },

  slide2: {
    flightTopLine1: "It was a big year!",
    flightTopLine2: "We’ve taken off together in 2024",
    flightIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/slide-2/flightIcon.svg",
    roadIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/slide-2/roadIcon.svg",
    roadDividerIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/slide-2/roadDividerIcon.svg",
    flightBottom: "around the world",
    flightTimes: 12,
    flightUnit: "flights",
    backgroundImage: "https://cxyearinreview.rhmdev.info/public-assets/exports/BgOnly02.jpg",
  },

  slide3: {
    miles0Top: "And welcomed",
    milesLabel: "12,089",
    // miles: 0,
    miles: memberMiles,
    miles0Fact: "17+ million people on board.",
    miles0Bottom: "That’s the population of Kazakhstan!",
    miles1PlusTop: "That’s about",
    miles1PlusUnit: "miles",
    miles1PlusGlobeCount: "{count} time",
    miles1PlusBottom: "around the globe",
    milesOrLabel: "or",
    earthIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/slide-3/earthIcon.svg",
    hongkongImage:
      "https://cxyearinreview.rhmdev.info/public-assets/slide-3/hongkong.svg",
    backgroundImage: "https://cxyearinreview.rhmdev.info/public-assets/exports/BgOnly03.jpg",
  },

  slide4: {
    cloudTopLine1: "In other words,",
    cloudTopLine2: "we've spent",
    cloudBottom: "Together in the sky",
    cloudDays: 289,
    cloudUnit: "day",
    //wait for Cupi to upgrade asset to have background transparency
    cloudIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/slide-4/cloudIcon.svg",
    backgroundImage: "https://cxyearinreview.rhmdev.info/public-assets/exports/BgOnly04.jpg",
  },

  slide5: {
    landIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/slide-5/landIcon.svg",
    tripTop:
      "Dreaming about your favourite trips? Last year, you spent the most time in",
    cities: ["Hong Kong", "Tokyo", "Bali"],
    backgroundImage: "https://cxyearinreview.rhmdev.info/public-assets/exports/BgOnly05.jpg",
  },

  slide6: {
    bankedMilesTopLine1: "Speaking of rewarding trips...",
    bankedMilesTopLine2: "our members have banked",
    asiaMilesIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/slide-6/asiaMilesIcon.svg",
    asiaMilesEarned: "36,000,000,000",
    bankedMilesBottom: "Asia Miles this year",
    backgroundImage: "https://cxyearinreview.rhmdev.info/public-assets/exports/BgOnly06.jpg",
  },

  slide7: {
    favWaysTop: "The top ways our members earned?",
    ways: ["Wellness", "Flights", "Payment"],
    farWaysBottom: "Incredible rewards, here you come",
    backgroundImage: "https://cxyearinreview.rhmdev.info/public-assets/exports/BgOnly07.jpg",
  },

  slide8: {
    endLabel:
      "That’s a wrap! Here’s to more adventures together with Cathay in",
    endYear: "2025",
    shareButtonLabel: "Share your journey",
    airplaneIcon:
      "https://cxyearinreview.rhmdev.info/public-assets/slide-8/airplaneIcon.svg",
    backgroundImage: "https://cxyearinreview.rhmdev.info/public-assets/exports/BgOnly08.jpg",
    onShareJourney: () => {
      console.log('slide8.onShareJourney() triggered');
    },
  },

  shareJourney: {
    shareTitle: "Select",
    mobileShareButtonLabel: "Share your journey",
    desktopShareButtonLabel: "Download",
    shareButtonIcon: "https://cxyearinreview.rhmdev.info/public-assets/share-journey/arrowRight.svg",
    shareButtonIconWhite: "https://cxyearinreview.rhmdev.info/public-assets/share-journey/arrowRightWhite.svg",
    closeIcon:"https://cxyearinreview.rhmdev.info/public-assets/share-journey/closeIcon.svg",
    loadingLabel: "Preparing Your Journey's Export....",
    onShareJourneySlides: (slides) => {
      console.log('you shared/downloaded these slides:', slides);
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ParallaxProvider>
      <YearInReviewAnimation
        general={data.general}
        slide0={data.slide0}
        slide1={data.slide1}
        slide2={data.slide2}
        slide3={data.slide3}
        slide4={data.slide4}
        slide5={data.slide5}
        slide6={data.slide6}
        slide7={data.slide7}
        slide8={data.slide8}
        shareJourney={data.shareJourney}
      />
    </ParallaxProvider>
  </React.StrictMode>,
  document.getElementById("root-yearinreview")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
